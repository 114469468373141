import React from "react";
import './SearchBar.styles.scss';
import Search from '../../images/search 1.svg';

function SearchBar({ searchQuery, handleSearch, backgroundColor, width }) {
  const searchDivStyle = {
    backgroundColor: backgroundColor || '#FFF',
    width: width || '46.6vw',
  };

  return (
    <div className="search-div" >
      <input
        style={searchDivStyle}
        className="search-bar"
        type="text"
        placeholder='Search'
        onChange={handleSearch}
        value={searchQuery}
      />
    </div>
  );
}

export default SearchBar;
