/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useEffect } from 'react';
import { getTokenFromLS, parseJSON } from '../lib/helpers';
import { setJobIdsToLS } from '../lib/jobIdToLS';
import { useHistory } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { log } from 'logrocket';

const CollectionContextProvider = ({ children }) => {
  const history = useHistory();

  const [collectionData, setCollectionData] = useState([]);

  const [noImgFieldsCollections, setNoImgFieldsCollections] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [message, setMessage] = useState({ msg: '', type: '' });
  const [showPricingBtn, setShowPricingBtn] = useState(false);
  const [showOptimizeBtn, setShowOptimizeBtn] = useState(true);
  const [typeOfOptimization, setTypeOfOptimization] = useState('lossy');
  const [btnText, setBtnText] = useState('Optimize');
  const [appApiKey, setAppApiKey] = useState('');
  const [appSiteId, setAppSiteId] = useState('');

  const addAdditionalFields = obj => {
    const fieldsToAdd = {
      isChecked: false,
      resizeStrategy: null,
      imageSizeToResize: null,
      lossy: true,
      selectValue: 'NULL',
      isEcomProd: false,
      isAutoOptimize: false
    };
    return { ...obj, ...fieldsToAdd };
  };

  const updateCollectionData = (arr) => {
    const localStorageData = localStorage.getItem('collectionDataState');
    const optimizationStatus = localStorage.getItem('optimizationStatus')
    if(localStorageData && optimizationStatus){
      const parsedData = JSON.parse(localStorageData);
      setCollectionData(parsedData.collectionData)
    }
    else if (Array.isArray(arr)) {
      setCollectionData(arr);
    }
  };

  const loopAndUpdateData = (arr, siteId) => {
    const updatedData = arr.map(ele => {
      if (ele.collectionFields.length) {
        ele.collectionFields = ele.collectionFields.map(fieldData => {
          fieldData = addAdditionalFields(fieldData);
          if (ele.collectionDetails.slug === 'sku') {
            fieldData.isEcomProd = true;
            fieldData.prodSiteId = siteId;
          }
          return fieldData;
        });
      } else {
        setNoImgFieldsCollections(prev => [...prev, ele.name]);
      }
      return ele;
    }).filter(daEle => daEle.collectionFields.length);
    const localStorageData = localStorage.getItem('collectionDataState');
    const optimizationStatus = localStorage.getItem('optimizationStatus')

    if(localStorageData && optimizationStatus){
      const parsedData = JSON.parse(localStorageData);
      setCollectionData(parsedData.collectionData)
    }else{
      setCollectionData(updatedData);
    }
  };

  const fetchCollections = ({ api, siteId }) => {
    setMessage(
      { msg: 'Fetching collections from Webflow CMS which contain images.', type: 'info' });
    fetch(`${process.env.REACT_APP_API_ENDPOINT}webflow/${api}/collections/${siteId}`, {
      method: 'GET', headers: {
        'Content-Type': 'application/json', 'token': getTokenFromLS()
      }
    })
      .then(parseJSON)  // parse it to Json
      .then(data => {
        const { success, collectionDetailsArr } = data;
        if (success && collectionDetailsArr && collectionDetailsArr.length) {
          loopAndUpdateData(collectionDetailsArr, siteId);
          setMessage({ msg: 'Collections Loaded', type: 'success' });
        }else if ( success === false && data.msg === 'Invalid Site key') {
          setMessage({
            msg: 'Token is invalid',
            type: 'error'
          });
        }
        else {
          setMessage({
            msg: 'Unable to find collections in the site. Make sure the site has CMS collections.',
            type: 'error'
          });
        }
      })
      .catch(err => {
        setMessage({ msg: 'This token is invalid, Please add new API key', type: 'error' });
        Bugsnag.notify(err);
      });
  };

  useEffect(() => {
    if (noImgFieldsCollections.length) {
      const message = `${noImgFieldsCollections.join(
        ', ')} collections doesn't include any image fields.`;
      setMessage({ msg: message, type: 'info' });
    }
  }, [noImgFieldsCollections]);

  const checkIfAnySelected = () => {
    const isSelected = collectionData.map(ele => ele.collectionFields)
                                     .flat()
                                     .find(obj => obj.isChecked === true);
    return !!isSelected;
  };

  useEffect(() => {
    const isSelected = checkIfAnySelected();
    if (isSelected) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [collectionData]);

  //auto optimze
  const autoOptimizeFields = async (fieldsData, isLossy) => {
    let autoOptimizedFields = [];
    Object.keys(fieldsData).forEach(fieldName => {
      fieldsData[fieldName].filter(field => field.isAutoOptimize).map(field => {
        return autoOptimizedFields = [
          ...autoOptimizedFields, {
            fieldId: field.id,
            imageSizeToResize: field.imageSizeToResize,
            resizeStrategy: field.resizeStrategy
          }];
      });
    });

    if (autoOptimizedFields && autoOptimizedFields.length) {
      try {
        await fetch(`${process.env.REACT_APP_API_ENDPOINT}api/auto-optimize-fields`, {
          method: 'POST', headers: {
            'Content-Type': 'application/json', token: getTokenFromLS()
          }, body: JSON.stringify({
            localSiteId: appApiKey, autoOptimizedFields, lossy: isLossy
          })
        });
      } catch (error) {
        setMessage({
          msg: 'Something Went Wrong.. Dont worry we are handling at Backend.', type: 'info'
        });
        Bugsnag.notify(error);
      }
    }
  };

  const disableAutoOptimizeField = async (fieldId) => {
    await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/disable/${appApiKey}/auto-optimized-field/${fieldId}`,
      {
        method: 'DELETE', headers: {
          'Content-Type': 'application/json', token: getTokenFromLS()
        }
      })
      .then(parseJSON) // parse it to Json
      .then((data) => {
        if (!data.success) return setMessage({
          msg: 'Something Went Wrong.. Dont worry we are handling at Backend.', type: 'error'
        });

        setMessage({ msg: 'Deleted', type: 'success' });
      })
      .catch((err) => {
        setMessage({
          msg: 'Something Went Wrong.. Dont worry we are handling at Backend.', type: 'info'
        });
        Bugsnag.notify(err);
      })
  };

  // Optimize Collection Function
  const addCollectionForOptimization = async (fieldsData) => {
    const isLossy = typeOfOptimization === 'lossy';

    await autoOptimizeFields(fieldsData, isLossy);
    return Promise.all(Object.keys(fieldsData)
                             .map((field) => fetch(
                               `${process.env.REACT_APP_API_ENDPOINT}api/optimize-collection`, {
                                 method: 'POST', headers: {
                                   'Content-Type': 'application/json', token: getTokenFromLS()
                                 }, body: JSON.stringify({
                                   secret: appApiKey,
                                   collection: fieldsData[field],
                                   collectionName: field,
                                   lossy: isLossy
                                 })
                               })
                               .then(parseJSON) // parse it to Json
                               .then(data => {
                                 if (data.length>0) {
                                   setMessage({
                                     msg: 'You have finished your quota for the month. Upgrade to continue optimizing images or reach out to us via chat to add more quota for the month.',
                                     type: 'error'
                                   });
                                 }
                                 return data.jobIdsArr || [];
                               })
                               .catch((err) => {
                                 setMessage({
                                   msg: 'Something Went Wrong.. Dont worry we are handling at Backend.',
                                   type: 'info'
                                 });
                                 Bugsnag.notify(err);
                               })))
                  .then((data) => {
                    if (data.length > 0) {
                      const jobIds = data.flat().filter(ech => ech !== undefined);
                      return jobIds;
                    } else {
                      setMessage({
                        msg: 'Something Went Wrong.. Dont worry we are handling at Backend.',
                        type: 'info'
                      });
                    }
                  })
                  .catch((err) => {
                    setMessage({
                      msg: 'Something Went Wrong.. Dont worry we are handling at Backend.',
                      type: 'info'
                    });
                    Bugsnag.notify(err);
                  });
  };

  const submitChange = async () => {
    setIsBtnDisabled(true);
    setBtnText('Please Wait..');

    const filteredItems = collectionData.filter(echCol => {
      const hasCheckFields = echCol.collectionFields.find(field => field.isChecked);
      return !!hasCheckFields;
    })
                                        .reduce((acc, ele) => {
                                          const collectionName = ele.collectionDetails.displayName;
                                          if (acc[collectionName]) {
                                            const oldFields = acc[collectionName];
                                            const newFields = ele.collectionFields.filter(
                                              field => field.isChecked);
                                            acc[collectionName] = [...oldFields, ...newFields];
                                          } else {
                                            acc[collectionName] = ele.collectionFields.filter(
                                              field => field.isChecked);
                                          }
                                          return acc;
                                        }, {});

    //send user collection optimized to BE
    // sendCollectionData(filteredItems);

    //Send Request to Optimize Collection
    const jobsArray = await addCollectionForOptimization(filteredItems);
    if (jobsArray.length) {
      // Save to local Storage
      setJobIdsToLS(jobsArray);
      // Push Route using Dynamic Data
      // history.push(`/pixie/optimize-fields?jobs=${jobsArray.join(',')}`);
      return jobsArray;
    } else {
      setBtnText('Error');
      setShowPricingBtn(true);
      setShowOptimizeBtn(false);
    }
  };

  return (<CollectionContext.Provider value={{
    collectionData,
    setAppApiKey,
    setAppSiteId,
    submitChange,
    btnText,
    message,
    isBtnDisabled,
    updateCollectionData,
    loopAndUpdateData,
    fetchCollections,
    disableAutoOptimizeField,
    showOptimizeBtn,
    showPricingBtn,
    typeOfOptimization,
    setTypeOfOptimization
  }}>
    {children}
  </CollectionContext.Provider>);
};

export const CollectionContext = createContext();

export default CollectionContextProvider;
