import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Modal from "../../components/Modal/Modal.component";
import SiteBox from "../../components/SiteBox/SiteBox.component";
import { parseJSON } from "../../lib/helpers";
import plus from "../../images/add.svg";
import HomeImage from "../../images/home-image.svg"
import "./AddSite.styles.scss";
import Bugsnag from "@bugsnag/js";
import SkeletonUI from "../../components/SkeletonUI/SkeletonUI.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import OauthBtn from "../../components/OauthBtn/OauthBtn.component";
import SearchBar from "../../components/SearchBar/SearchBar.component"
const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;
function AddSite() {
    const [name, setName] = useState("");
    const [siteIds, setSiteIds] = useState([]);
    const [sitesData, setSitesData] = useState([]);
    const [checkSite,setCheckSite] = useState(true);
    const [skeletonLoad, setSkeletonLoad] = useState(true);
    const [fetchSites, setFetchSites] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredSites, setFilteredSites] = useState([]);

    const closeOpenModal = () => {
        setIsModalOpen(prevState => !prevState);
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-username-sites`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                token: window.localStorage.getItem("token"),
            },
        })
            .then(parseJSON) // parse it to Json
            .then((data) => {
                if (data.success) {
                    const {
                        user: { sites, username },
                    } = data;
                    // if (sites instanceof Array && sites.length < 1) {
                        // window.location = url;
                    // }
                    setName(username);
                    console.log(sites)
                    setSiteIds(sites);
                    setFetchSites(true);
                    if(sites.length == 0){
                        setCheckSite(false)
                    }
                }
            })
            .catch(err => {
                console.log({ err });
                Bugsnag.notify(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (siteIds.length && fetchSites) {
            setFetchSites(false);
            Promise.all(
                siteIds.map((siteObj) =>
                    fetch(
                        `${process.env.REACT_APP_API_ENDPOINT}api/user-sites/${siteObj._id}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                token: window.localStorage.getItem("token"),
                            },
                        }
                    )
                        .then(parseJSON) // parse it to Json
                        .then((data) => data)
                )
            )
                .then((listArr) => {
                    const filteredData = listArr.reduce((acc, siteIn) => {
                        const { success, mappedSitesData } = siteIn;
                        if (success) {
                            acc = [...acc, ...mappedSitesData];
                        }
                        return acc;
                    }, []);
                    console.log("filtredData:" ,filteredData)
                    const uniqueSites = Object.values(
                        filteredData.reduce((acc, site) => {
                          acc[site.siteUniqueId] = site;
                          return acc;
                        }, {})
                      );                      
                    console.log("uniqueSites: ",uniqueSites)
                    setSkeletonLoad(false);
                    setSitesData(uniqueSites);
                    if(uniqueSites.length >0){
                        setCheckSite(true);
                    }else{
                        setCheckSite(false);
                    }
                })
                .catch((err) => {
                    console.log({ err });
                    Bugsnag.notify(err);
                    setCheckSite(false)
                });
        }
    }, [fetchSites]);
    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
      
        const filtered = sitesData.filter((site) =>
          site.name.toLowerCase().includes(query.toLowerCase())
        );
      
        setFilteredSites(filtered);
      };
      const displayedSites = searchQuery ? filteredSites : sitesData;
      return (
        <div className="main-block flex-column">
            {sitesData.length > 0 && (
                <SearchBar handleSearch={handleSearch} searchQuery={searchQuery}/>
            )}
            <div className={`flex-wrap-block ${siteIds.length > 0 && checkSite ? 'margin-top-10' : ''}`}>

            {skeletonLoad && siteIds.length
                ? 
                siteIds.map((site) => (
                <SkeletonUI key={site._id} height={'21.41vh'} width={'16.27vw'} />
                ))
            : (
                <div className="site-box-container" style={{ width: sitesData.length > 0 ? "50vw" : "auto" }}>
                <div className="site-box-scroll-container">
                <div className="flex-wrap-block ">
                {sitesData.length ? (
                    <div className="add-site-box" onClick={closeOpenModal}>
                        Add a Webflow Site
                    </div>
                ): (null)}
                {sitesData.length ? (
                searchQuery !== "" ? (
                    displayedSites.map((ele) => (
                    <SiteBox siteInfo={ele} key={ele.siteUniqueId} reauth={ele.reauth===true?true:false}/>
                    
                    ))
                ) : (
                    sitesData.map((ele) => (
                    <SiteBox siteInfo={ele} key={ele.siteUniqueId} reauth={ele.reauth===true?true:false}/>
                    ))
                )
                ): 
            (
            <>
            {checkSite ? (null) : (
            <div className="outer-white-box">
                <div className="inner-white-box">
            <Container maxWidth="xl">
                <div className="new-site">
                    <div className="content-box">
                    <div className="heading-lite">
                        Welcome, <span className="username">{name}</span>!
                    </div>
                    <div className="sub-text">
                        Click below to add the Webflow site you want to optimize
                    </div>
                        <CustomButton style={{ width: '17.447vw' }} onClick={closeOpenModal} black={true}>

                            {/* <Link className="add-site-btn" to="/pixie/add-api-key"> */}
                            {/* <img src={plus} className="plus" alt="plus" /> */}
                            <p className="click-text">Add Webflow site</p>
                            {/* </Link> */}
                        </CustomButton>

                    </div>
                    <div className="image-container">
                            <img src={HomeImage} style={{width: '31.25vw', height: "21.036vh"}} className="home" alt="home" />
                    </div>
                    </div>
            </Container>

            </div>

            </div>
            )}
            </>
            )
        }

        </div>
        </div>
        </div>
        
            )
            }
            </div>
            {
                isModalOpen && (
                    <Modal>
                        <MultiOptionBox toggleModal={closeOpenModal} />
                    </Modal>
                )
            }
        </div>
    );
}


const MultiOptionBox = ({ toggleModal }) => {
    return (
        <div className="box-outline" id="modal-div">
            <div className="inner-box">
                <div onClick={toggleModal} className="modal-close">
                    <CloseSharpIcon fontSize="large" style={{ color: '#FACE48' }} />
                </div>

                <h3 className="title">
                    Add your Webflow site
                </h3>
                <p className="desc">
                    It is as simple as signing in your Webflow account.
                </p>
                <div className="btn-box">

                    <OauthBtn />
                    <span className="or">or</span>
                    <Link className="custom-links" to="/pixie/add-api-key">
                        <CustomButton black={true}>Add API Key</CustomButton>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AddSite;
