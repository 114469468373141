import React from "react";
import { Switch, Route, Redirect ,Link} from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import LogRocket from "logrocket";
import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs.component";
import UserLogsDetails from "./pages/UserLogsDetails/LogsDetails.component";
import UserLogs from "./pages/UserLogs/Logs.component";
import AddSite from "./pages/AddSite/AddSite.component";
import AddApiKey from "./pages/AddApiKey/AddApiKey.component";
import StraightForwardPricing from "./pages/StraightForwardPricing/StraightForwardPricing.component";
import Collections from "./pages/Collections/Collections.component";
import NotFound from "./pages/NotFound/NotFound.component";
import Navbar from "./components/Navbar/Navbar.component";
import NavMenu from "./components/NavMenu/NavMenu.component";
import Login from "./pages/Login/Login.components";
import CustomExternalRedirect from "./components/CustomExternalRedirect";
import OptimizeFields from "./pages/OptimizeFields/OptimizeFields.components";
import SignUp from "./pages/SignUp/SignUp.component";
import Modal from "./components/Modal/Modal.component";
import Profile from "./components/Profile/Profile.component";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.component";
import ProfilePage from "./pages/Profile/ProfilePage.component"
import Products from "./pages/Products/Products.component";
import CollectionNameAlert from "./components/CollectionNameAlert/CollectionNameAlert.component";
import { parseJSON } from "./lib/helpers";
import PixiePricing from "./pages/PixiePricing/PixiePricing.component";
import "./App.css";
import ResetPassword from "./pages/ResetPassword/ResetPassword.component";
import VerifyResetPassword from "./pages/VerifyResetPassword/VerifyResetPassword.component";
import VerifyLicenseKey from "./pages/VerifyLicenseKey/VerifyLicenseKey.component";
import Oauth from "./pages/Oauth/Oauth.component";
import CollectionContextProvider from "./contexts/CollectionContext";
import UserQuotaContextProvider from "./contexts/UserQuotaContext";

import StraightForwardPricingModalSuccess from "./pages/StraightForwardPricing/StraightForwardPricing.modal.success";
import StraightForwardPricingModalFailed from "./pages/StraightForwardPricing/StraightForwardPricing.modal.failed";

const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;

const initialState = {
  key: "",
  dataLoaded: false,
  collections: [],
  selectedCollections: {},
  collectionDetails: {},
  route: "login",
  selectedCollectionFields: {},
  isProfileOpen: false,
  user: {
    _id: null,
    name: "",
    email: "",
    sites: [],
    imageCount: 0,
    planQuota: 0,
    showCode: false,
    type: ""
  },
  isSignedIn: false,
  subscription: {
    isSubscribed: false,
    planId: {},
    subId: {},
    status: null,
    customerId: '',
    planName: '',
    startDate: null,
    endDate: null,
    cancelsAt: null
  },
  upcomingSubscription: {
    planId: null,
    startDate: null
  }
};

class App extends React.Component {
  constructor() {
    super();
    this.state = initialState;
  }

  componentDidMount() {
    const token = window.localStorage.getItem("token");
    if (token) {
      this.loadUserProfile(token);
    } else {
      this.setState({ isSignedIn: false });
      this.setState({ dataLoaded: true});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isSignedIn !== this.state.isSignedIn) {
      const token = window.localStorage.getItem("token");
      if (token) {
        this.loadUserProfile(token);
      }
    }
  }

  loadSubscriptions = async (data) => {
    this.setState({
      subscription: {
        isSubscribed: data.current,
        planid: data.plan ? data.plan : this.state.subscription.planid,
        subId: data.subId ? data.subId : this.state.subscription.subId,
        customerId: data.cusId ? data.cusId : this.state.subscription.customerId
      }
    })
    console.log(this.state.subscription)
  }

  loadPlans = async (data) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/get-plans/${data.planId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 401 || res.status === 400) {
        localStorage.clear();
      }
      return res;
    })
      .then(parseJSON)
      .then((data) => {
        this.loadSubscriptions({ current: true, plan: data.plan })
      })
      .catch((err) => {
        console.log("err", err);
        localStorage.clear();
        Bugsnag.notify(err);
      });
  }

  loadUser = (data) => {
    if (data.subscription) {
      this.setState({
        subscription: {
          isSubscribed: true,
          planId: data.subscription.planId,
          subId: data.subscription.subscriptionId,
          status: data.subscription.status,
          customerId: data.subscription.customerId,
          planName: data.subscription.plan.name,
          startDate: data.subscription.startDate,
          endDate: data.subscription.endDate,
          cancelsAt: data.subscription.cancelsAt,
        }
      });
      if (data.upcomingSubscription) {
        this.setState({
          upcomingSubscription: {
            planId: data.upcomingSubscription.planId,
            startDate: data.upcomingSubscription.startDate,
          }
        })
      }
    }
    this.setState({
      user: {
        _id: data._id,
        name: data.username,
        email: data.email,
        sites: data.sites,
        imageCount: data?.imageCount ? data.imageCount : 0,
        planQuota: data.planQuota,
        showCode: data.showCode,
        type: data?.type || "BASIC"
      },
      isSignedIn: true,
    });
  };

  // set User data in Log rocket
  loadLogRocket = (userData) => {
    const { username, email, _id } = userData;
    LogRocket.identify(_id, {
      name: username,
      email: email,
    });
  };

  // set User data in bugsnag
  loadBugSnag = (userData) => {
    const { username, email, _id } = userData;
    Bugsnag.setUser(_id, email, username);
  };

  // loadSubscriptionsfromdb = (userId) => {
  //   fetch(`${process.env.REACT_APP_API_ENDPOINT}subscription/get-subscriptions/${userId}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       if (res.status === 401 || res.status === 400) {
  //         localStorage.clear();
  //       }
  //       return res;
  //     })
  //     .then(parseJSON)
  //     .then((data) => {
  //       // console.log(dat)
  //       // console.log(data.sub[0]);
  //       // console.log(data.success)
  //       console.log(data);
  //       if (data.success && data.sub[0]) {
  //         let obj = data.sub[0].currentSubscription;
  //         console.log(obj)
  //         if (obj) {
  //           this.loadSubscriptions({ current: true, subId: obj.subscriptionId, cusId: obj.customerId, plan: obj.planId })
  //           this.loadPlans({ planId: obj.planId, usagePlanId: obj.usagePlanId })
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       localStorage.clear();
  //       Bugsnag.notify(err);
  //     });
  // }

  loadUserProfile = (token) => {
    if (token) {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-user-profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
        .then((res) => {
          if (res.status === 401 || res.status === 400) {
            localStorage.clear();
          }
          return res;
        })
        .then(parseJSON)
        .then((data) => {
          if (data.success && data.user) {
            // let obj = data.user.subscriptions.find(o => o.current === true);
            // console.log(obj)
            // if (obj) {
            //   this.loadPlans({ planId: obj.planId, usagePlanId: obj.usagePlanId })
            // }
            // this.loadSubscriptionsfromdb(data.user._id)
            this.loadUser(data.user);
            this.setState({
              dataLoaded: true,
            });
            this.loadLogRocket(data.user);
            this.loadBugSnag(data.user);
          }
        })
        .catch((err) => {
          console.log("err", err);
          localStorage.clear();
          Bugsnag.notify(err);
        });
    }
  };

  onRouteChange = (route) => {
    if (route === "signout") {
      localStorage.clear();
      return this.setState({
        ...initialState,
        dataLoaded: true,
      });
    }
  };

  setSignedIn = (val) => {
    this.setState({ isSignedIn: val });
  };

  handleNext = (data) => {
    this.setState({ selectedCollections: data, route: "collectionFields" });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      isProfileOpen: !prevState.isProfileOpen,
    }));
  };

  render() {
    const {
      isSignedIn,
      isProfileOpen,
      subscription,
      dataLoaded
    } = this.state;
    return (
      <div className="App">
        <Navbar
          onRouteChange={this.onRouteChange}
          isSignedIn={isSignedIn}
          toggleModal={this.toggleModal}
          userImageCount={this.state.user.imageCount}
          userPlanQuota={this.state.user.planQuota}
          userName={this.state.user.name}
          showCode={this.state.user.showCode}
          currentSubscription={this.state.subscription}
          dataLoaded={dataLoaded}
        />
        <Switch>
          <Route exact path="/login">

            <Login isSignedIn={isSignedIn} setSignedIn={this.setSignedIn} />
          </Route>

          <Route exact path="/signup">

            <SignUp isSignedIn={isSignedIn} setSignedIn={this.setSignedIn} />
          </Route>
          <ProtectedRoute
            exact
            path="/pixie/pricing"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <StraightForwardPricing user={this.state.user} currentSubscription={this.state.subscription} upcomingSubscription={this.state.upcomingSubscription} />
          </ProtectedRoute>
          <Route exact path="/reset-password">
            <ResetPassword isSignedIn={isSignedIn} />
          </Route>
          <Route exact path="/reset-password/:token">
            <VerifyResetPassword isSignedIn={isSignedIn} />
          </Route>
      <div className="main-container">

        <div className="nav-menu">
            
            {isSignedIn &&  (
            
            <NavMenu
            onRouteChange={this.onRouteChange}
            toggleModal={this.toggleModal}
            />
          )}
        </div>
        <div className="content">
        {isProfileOpen && (
          <Modal>
            <Profile
              isProfileOpen={isProfileOpen}
              user={this.state.user}
              sub={this.state.subscription}
              toggleModal={this.toggleModal}
              loadUser={this.loadUser}
            />
          </Modal>
        )}
        <Switch>
          <ProtectedRoute exact path="/" isSignedIn={isSignedIn} dataLoaded={dataLoaded}>
            {/* <Products /> */}
            <Redirect to="/pixie" />
          </ProtectedRoute>

          <ProtectedRoute exact path="/pixie" isSignedIn={isSignedIn} dataLoaded={dataLoaded}>
            <AddSite />
          </ProtectedRoute>

          {/* <ProtectedRoute
            exact
            path="/pixie/pricing"
            isSignedIn={isSignedIn}
          >
            <StraightForwardPricing user={this.state.user} currentSubscription={this.state.subscription} upcomingSubscription={this.state.upcomingSubscription} />
          </ProtectedRoute> */}

          <ProtectedRoute
            exact
            path="/pixie/pricing/success"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <StraightForwardPricingModalSuccess />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/pixie/pricing/cancel"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <StraightForwardPricingModalFailed />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/pixie/add-api-key"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <AddApiKey />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/pixie/:api/collections/:siteId"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <CollectionContextProvider>
              <Collections />
            </CollectionContextProvider>
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/pixie/optimize-Fields"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <UserQuotaContextProvider>
              <OptimizeFields onLoadUser={this.loadUserProfile} />
            </UserQuotaContextProvider>
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/pixie/verify-license"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}

          >
            <VerifyLicenseKey />
          </ProtectedRoute>
          <ProtectedRoute
            exact path="/profile"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <ProfilePage user={this.state.user} subscription={this.state.subscription}/>
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/cancel"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/logs"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <UserLogs />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/logs/:index/:slugId"
            isSignedIn={isSignedIn}
            dataLoaded={dataLoaded}
          >
            <UserLogsDetails />
          </ProtectedRoute>
          <Route exact path="/pixie/oauth">
            <Oauth isSignedIn={isSignedIn} setSignedIn={this.setSignedIn} />
          </Route>

          {/* <ProtectedRoute exact path="/pixie/oauth" isSignedIn={isSignedIn}>
            <Oauth />
          </ProtectedRoute> */}

          <Route exact path="/pixie/buy">
            <PixiePricing />
          </Route>

          <Route exact path="/pixie/install">
            <CustomExternalRedirect to={url} />
          </Route>



          <Route>
            <NotFound />
          </Route>
        </Switch>
        </div>
      </div>
      </Switch>
        {/* <CollectionNameAlert /> */}
      </div>
    );
  }
}

export default App;
