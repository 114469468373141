import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ActionAlerts from "../../components/Alert/Alert.component"
import { parseJSON, checkStatus } from '../../lib/helpers'
import scrn1 from '../../images/step_1.PNG';
import scrn2 from '../../images/step_2.png';
import scrn3 from '../../images/step_3.png';
import CustomButton from '../../components/CustomButton/CustomButton.component';

import './AddApiKey.styles.scss';
import Bugsnag from '@bugsnag/js';

function AddApiKey() {
    const [key, setKey] = useState('');
    const [message, setMessage] = useState({ msg: '', type: "" });
    const [btnText, setBtnText] = useState('Validate Key');
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    const history = useHistory();

    useEffect(() => {
        if(key.length) {
            setIsBtnDisabled(false);
        } else {
            setIsBtnDisabled(true);
        }
    }, [key])

    const onErrorHandle = (msg) => {
        setMessage({ msg: msg || "Unable to authicate API key. Make sure the Key is correct.", type: "error" })
        setBtnText("Retry");
        setKey('');
    }

    const handleSubmit = event => {
        event.preventDefault();
        const token = window.localStorage.getItem('token');
        if (token) {
            setBtnText("Loading...");
            setIsBtnDisabled(true);
            fetch(`${process.env.REACT_APP_API_ENDPOINT}api/user-full-info`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                },
                body: JSON.stringify({ secret: key })
            })
                .then(parseJSON)
                .then(data => {
                    console.log(data)
                    if (data.success && data.siteInfo) {
                        const {siteUniqueId, siteId} = data.siteInfo;
                        setMessage({ msg: 'Sucessfully Added Key', type: "success" })
                        setKey('');
                        history.push(`/pixie/${siteId}/collections/${siteUniqueId}`);
                    } else {
                        onErrorHandle(data.msg);
                    }
                })
                .catch(err => {
                    onErrorHandle();
                    Bugsnag.notify(err);
                })
        } else {
            history.push('/login');
        }
    }

    const closeBtn = () => {
        history.push('/pixie');
    }

    const handleChange = event => {
        const key = event.target.value;
        setKey(key);
    }

    return (
        <div className="main-block">
            <Container maxWidth="xl">
                <div className="closeBtn" onClick={closeBtn}>
                    <CloseRoundedIcon fontSize="large" style={{ color: '#d9d4e7' }} />
                </div>
                <h2 className="heading-lite">
                    Add your API key of the Webflow site whose CMS images you want to optimize.
                </h2>
                <div>
                    <form onSubmit={handleSubmit} className="add-api-block">
                        <input type="text" name="key" min="30" value={key} onChange={handleChange} />
                        <CustomButton style={{lineHeight: '0'}}disabled={isBtnDisabled} black={true}>{btnText}</CustomButton>
                    </form>
                    {
                        message.msg ?
                            <ActionAlerts text={message.msg} type={message.type} /> :
                            null
                    }
                </div>
                <div className="more-info-block">
                    <p className="bold">
                        <a href="https://university.webflow.com/article/using-the-webflow-cms-api#:~:text=Go%20to%20your%20project%20settings%20%E2%86%92%20Integrations%20%E2%86%92%20API%20Access" target="blank">Here</a> are the steps to generate an API key
                    </p>
                    <div className="help-image-wrapper">
                    <div className="more-info-block bold">Step 1</div>
                    <img src={scrn1} style={{height: '60vh',width: '20vw'}} className="img-mw-800" alt="" />
                    <div className="more-info-block bold">Step 2</div>
                    <img src={scrn2} className="img-mw-800" alt="" />
                    <div className="more-info-block bold">Step 3</div>
                    <img src={scrn3} className="img-mw-800" alt="" />
                    </div>
                </div>
            </Container>
        </div>
    )
}


export default AddApiKey;