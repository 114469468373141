import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './SiteBox.styles.scss';
import Modal from "../../components/Modal/Modal.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";
import OauthBtn from "../../components/OauthBtn/OauthBtn.component";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

const SiteBox = ({ siteInfo,reauth }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { previewUrl, name, siteId, siteUniqueId } = siteInfo;

    const closeOpenModal = () => {
        setIsModalOpen(prevState => !prevState);
    }

    console.log(reauth);
    if (reauth === true) {
        // Show the Modal with MultiOptionBox
        return (
          <>
            <div className="site-box" onClick={closeOpenModal}>
                <div className="site-img-block" style={{ backgroundImage: `url(${previewUrl})` }}></div>
                <div className="site-info">
                  <span className="site-name">{name}</span>
                </div>
            </div>
    
            {isModalOpen && (
              <Modal>
                <MultiOptionBox toggleModal={closeOpenModal} />
              </Modal>
            )}
          </>
        );
      } 
    return (
        <div className="site-box">
        <Link to={`/pixie/${siteId}/collections/${siteUniqueId}`}>
            <div className="site-img-block" style={{ backgroundImage: `url(${previewUrl})`}}>
            </div>
            <div className="site-info">
                <span className="site-name">
                    {name}
                </span>
            </div>
        </Link>
        </div>
    )
}

const MultiOptionBox = ({ toggleModal }) => {
    return (
        <div className="box-outline" id="modal-div">
            <div className="inner-box">
                <div onClick={toggleModal} className="modal-close">
                    <CloseSharpIcon fontSize="large" style={{ color: '#FACE48' }} />
                </div>

                <h3 className="title">
                    Resync your Webflow site
                </h3>
                <p className="desc">
                    We shifted to webflow V2 api for your better experience. Please resync your website.
                </p>
                <div className="btn-box">

                    <OauthBtn />
                    <span className="or">or</span>
                    <Link className="custom-links" to="/pixie/add-api-key">
                        <CustomButton black={true}>Add API Key</CustomButton>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default SiteBox;