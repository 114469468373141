import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput.component';
import CustomButton from '../../components/CustomButton/CustomButton.component';
import ActionAlerts from '../../components/Alert/Alert.component';
import { useFormValidation } from '../../customHooks/formValidationHook';
import './Login.styles.scss';
import Bugsnag from '@bugsnag/js';
import webflow_logo from '../../images/webflow_logo.svg';
import LoginImage from '../../images/Group 80.png'

const clientId = process.env.REACT_APP_WEBFLOW_CLIENT_ID || "";
const url = `https://webflow.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=authorized_user%3Aread%20cms%3Aread%20cms%3Awrite%20sites%3Aread`;
function Login({ isSignedIn, setSignedIn }) {
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });
  const [message, setMessage] = useState({ msg: '', type: "" });
  const [errorMessage, setErrorMessage] = useState('');
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [btnText, setBtnText] = useState('Login');
  const { formErrors, validateForm, checkForFormErrors, btnDisabled, setBtnDisabled } = useFormValidation();
  const history = useHistory();
  const location = useLocation();
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (isSignedIn) {
      setUserSignedIn(true)
      history.replace(from);
    }
  }, [from, history, isSignedIn, setUserSignedIn, userSignedIn])


  const handleChange = event => {
    const { value, name } = event.target;
    checkForFormErrors(name, value);
    setLoginDetails(prevDetails => {
      return {
        ...prevDetails,
        [name]: value,
      }
    })
    const filled = Object.values(loginDetails).every(val => val.trim() !== '');
    setAllFieldsFilled(filled);
  }

  const saveAuthTokenInSession = (token) => {
    window.localStorage.setItem('token', token);
  }
  const webflowLogin = async () => {
    window.location = url;
  };
  const onSubmitLogin = (event) => {
    event.preventDefault();
    if(validateForm(formErrors)) {
    const { email, password } = loginDetails;
    setBtnDisabled(true);
    setBtnText('Please Wait...');
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/login`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email, password
        })
      })
        .then(response => response.json())
        .then(data => {
          setLoginDetails({
            email: '',
            password: '',
          })
          if (data.success === true && !data.errors) {
            setMessage({ msg: 'Login Successfull', type: "success" })
            saveAuthTokenInSession(data.token)
            setSignedIn(true)
            history.replace(from);
          } else {
            if (data?.message === 'Please Login With Webflow') {
              errHandle(data?.message)
            } else {
              setErrorMessage('Incorrect email or password.');
              errHandle();
            }
          }
        }).catch(err => {
          setErrorMessage('Error in logging you in! Please try again.');
          errHandle();
          Bugsnag.notify(err);
        })
    }
  }

  const errHandle = (errorMsg = null) => {
    setMessage({ msg: errorMsg ? errorMsg : 'Incorrect email or password.', type: "error" })
    setBtnText('Login');
  }

  return (
    <div className="flex-center">
      <div className="signin-container">
      <div className="signin-form">
        <h2 className="title-login">Login</h2>
        <p>Enter your credentials to access an account.</p>
        {/* {
          message.msg ?
            <ActionAlerts text={message.msg} type={message.type} /> :
            null
        } */}

<           div className="google-or-container" >
                <div className="google-button-container">
                <button className="google-button" onClick={webflowLogin}>
                    Login with Webflow
                </button>
                </div>
            </div>
        
        <div className="or-text tb-margin-24">or</div>

        <form onSubmit={onSubmitLogin} className="login-form">
          <div className='tb-margin-24'>
          <FormInput name="email" type="email" value={loginDetails.email} handleChange={handleChange} className={`box-input-signin ${formErrors.email || errorMessage ? 'error-box' : ''}`}     label="Email" required />
          {formErrors.email && <span className='error'>{formErrors.email}</span>}
          </div>
          <div className='tb-margin-24'>
          <FormInput name="password" type="password" value={loginDetails.password} handleChange={handleChange} className={`box-input-signin ${formErrors.password || errorMessage ? 'error-box' : ''}`}label="Password" required />
          {formErrors.password && <span className='error'>{formErrors.password}</span>}
          </div>
            <div className="center">
              <CustomButton type="submit" disabled={btnDisabled} black={allFieldsFilled ? 'black' : ''}>
                {btnText}
              </CustomButton>
            </div>
          {errorMessage && <span className="error-text">{errorMessage}</span>}
        </form>
        <div className="links-container">
        <p className="non-user">
        <Link className="link" to='/reset-password'>Forgot Password?</Link>
        </p>
        <p className="non-user">
          Not a member? <Link className="link" to='/signup'>Sign Up</Link>
        </p>
      </div>

    </div>
    <div className="signin-image" >
            <img src={LoginImage} alt="Image 1" className="base-image" />
      </div>
      </div>
      </div>
  )
}



export default Login;