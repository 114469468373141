import React, { useEffect, useState } from 'react';
import { getTokenFromLS, parseJSON } from "../../lib/helpers"; 
import './Logs.styles.scss';
import { Link } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs.component";
import CircularProgressIndicator from '../../components/CircularProgress/CircularProgress.component';

const UserLogsPage = () => {
  const [userLogs, setUserLogs] = useState([]);
  const [logsEmpty, setLogsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    try {
      // Fetch logs from the API
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}user/get-user-logs`, {
        headers: {
          'Content-Type': 'application/json',
          token: getTokenFromLS(),
        },
      });
      const data = await response.json();
      if (data.length === 0) {
        setLogsEmpty(true);
      }
      const sortedLogs = sortLogsByDateTime(data);
      setUserLogs(sortedLogs);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user logs:', error);
      setLogsEmpty(true);
      setIsLoading(false);
    }
  };
  const sortLogsByDateTime = (logs) => {
    return logs.sort((a, b) => {
      const dateTimeA = new Date(a.updatedAt);
      const dateTimeB = new Date(b.updatedAt);
      return dateTimeB - dateTimeA;
    });
  };

  const getUserTimezoneDate = (extractedDate) => {
    var date = new Date(extractedDate);

    var userOffset = new Date().getTimezoneOffset();

    userOffset = -userOffset;

    var userOffsetMillisecs = userOffset * 60 * 1000;

    var userDate = new Date(date.getTime() + userOffsetMillisecs);
    var formattedDate = userDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

    var formattedTime = userDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
    return  formattedDate + ' ' + formattedTime;
  };

  const groupLogsByDateAndCollection = () => {
    const groupedLogs = {};
    userLogs.forEach((log) => {
      const key = log.updatedAt.slice(0, -8); 
  
      if (!log.meta) {
        return;
      }
  
      const collectionName = Array.isArray(log.meta) ? log.meta[0].collectionName : log.meta.collectionName;
  
      if (!groupedLogs[key]) {
        groupedLogs[key] = {};
      }
  
      if (!groupedLogs[key][collectionName]) {
        groupedLogs[key][collectionName] = [];
      }
  
      groupedLogs[key][collectionName].push(log);
    });
  
    return groupedLogs;
  };
  const covertToKB = (size) =>{
    return (size/1024).toFixed(2)+'KB'
  }
  const covertToMB = (size) =>{
    return (size / 1024).toFixed(2) + 'MB';
  }
  const groupedLogs = groupLogsByDateAndCollection();

  return (
    <div className='main-box'>
      {isLoading ? (
        <div className='loader-div'>
          <CircularProgressIndicator value={null} className='check-box-btn' style={{color:"black", marginTop:'5vh'}}/>
        </div>
          ) : logsEmpty ? (
        <div className='empty'>
          <div className='empty-header'>No Optimizations Done Here.</div>
          <div className='empty-text'>
            You can <a href='/pixie'>optimize your Webflow sites.</a>
          </div>
        </div>
      ) : (
        <div>
          <div className='heading-wrapp'>
            {/* ... Breadcrumbs or other heading content ... */}
          </div>
          {Object.keys(groupedLogs).map((dateTimeKey, groupIndex) => (
            <div key={groupIndex}>
              <div className='header-wrapper'>
                <h2 className='collection-name'>{getUserTimezoneDate(dateTimeKey)}</h2>
              </div>
              <div className="white-box">
              {Object.keys(groupedLogs[dateTimeKey]).map((collectionName, logIndex) => (
                <>
                  <div className="header-wrapper">
                    <h3 className="collection-name">{collectionName}</h3>
                  </div>
                  <div className='light-grey-box header-box'>
                    <p>Field Name</p>
                    <p>Original Size</p>
                    <p>Optimized Size</p>
                    <p>Saved Size</p>
                    <p>Action</p>
                  </div>
                  {groupedLogs[dateTimeKey][collectionName].map((log, logIndex) => (
                    <div className='grp-data' key={logIndex}>
                      <div className={`data-box ${Array.isArray(log.meta) ? ' gap-btw-array' : 'gap-btw'}`}>
                        {Array.isArray(log.meta) ? (
                          log.meta.map((metaItem, metaIndex) => (
                            <>
                            <div className='meta-item'>
                              <p className='data-text'>{metaItem.slugName}</p>
                              <p className='data-text ' >{covertToKB(metaItem.originalImagesSize) > 10000 ? covertToMB((metaItem.originalImagesSize / 1024)) : covertToKB(metaItem.originalImagesSize)}</p>
                              <p className='data-text add-padding-left'>{covertToKB(metaItem.optimizedImagesSize) > 10000 ? covertToMB((metaItem.optimizedImagesSize / 1024)) : covertToKB(metaItem.optimizedImagesSize)}</p>
                              <p className='data-text add-padding-left'>{covertToKB(metaItem.totalSavedBytes) > 10000 ? covertToMB((metaItem.totalSavedBytes / 1024)) : covertToKB(metaItem.totalSavedBytes)}</p>
                              <p className='add-margin-right detail-class'>
                                <Link to={`/logs/${metaIndex}/${log.jobId}`} className="details-link">
                                  Details
                                </Link>
                              </p>
                              </div>
                              {metaIndex !== log.meta.length - 1 && (<div className="divider-line"></div>)}
                            </>
                          ))
                        ) : (
                          <>
                            <p className='data-text'>{log.meta.slugName}</p>
                            <p className='data-text'>{covertToKB(log.meta.originalImagesSize) > 10000 ? covertToMB((log.meta.originalImagesSize / 1024)) : covertToKB(log.meta.originalImagesSize)}</p>
                            <p className='data-text add-padding-left'>{covertToKB(log.meta.optimizedImagesSize) > 10000 ? covertToMB((log.meta.optimizedImagesSize / 1024)) : covertToKB(log.meta.optimizedImagesSize)}</p>
                            <p className='data-text add-padding-left'>{covertToKB(log.meta.totalSavedBytes) > 10000 ? covertToMB((log.meta.totalSavedBytes / 1024)) : covertToKB(log.meta.totalSavedBytes)}</p>
                            <p className='add-margin-right detail-class'>
                              <Link to={`/logs/00/${log.jobId}`} className="details-link">
                                Details
                              </Link>
                            </p>
                          </>
                        )}
                      </div>
                      {logIndex !== groupedLogs[dateTimeKey][collectionName].length - 1 && (<div className="divider-line"></div>)}
                    </div>
                  ))}

                  </>
              ))}
               </div>

            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserLogsPage;
