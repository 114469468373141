import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import './StraightForwardPricing.modal.css';
import success from '../../images/success.png';
import StraightForwardPricing from './StraightForwardPricing.component';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(31, 31, 31, .9)',
  },
  // content: {
  //   border: "2px solid rgba(31, 31, 31, 1)",
  //   borderRadius: "20px",
  //   position: 'absolute',
  //   top: '35%',
  //   left: '31.57%',
  //   right: '31.57%',
  //   bottom: '35%',
  //   background: '#fff',
  //   overflow: 'auto',
  //   WebkitOverflowScrolling: 'touch',
  //   outline: 'none',
  //   padding: "0px"
  // }
};

function StraightForwardPricingModalSuccess() {
  const [modalIsOpen, setIsOpen] = useState(true);
  const history = useHistory();
  const image_count = useRef();

  useEffect((() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    history.push('/pixie/pricing/success')

    const sessionid = searchParams.get('session_id');
    image_count.current = searchParams.get('image_count');

    if (sessionid && image_count) {
      history.push('/pixie/pricing/success');

    } else {
      history.push('/pixie/pricing');
    }
  }), []);

  function closeModal() {
    history.push('/pixie/pricing');
    setIsOpen(false);
  }

  return (
    <div className=''>
      <div className=''><StraightForwardPricing /></div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal Content"
        className="Modal"
      >
        <div>
          <div className='main-modal'>
            <img className='img-modal' src={success} alt="load" />
          </div>
          <div className='header-modal'>Payment Successful</div>
          <div className='content-modal-1'>Starter plan was selected successfully, you have</div>
          <div className='content-modal-2'>{image_count.current} compressions to look forward to!</div>
        </div>
      </Modal>
    </div>
  );
}


export default StraightForwardPricingModalSuccess;
