import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// import * as serviceWorker from "./serviceWorker";
import App from "./App";

import ErrorFallBack from "./components/ErrorFallBack/ErrorFallBack.component";

const isDevModeOn = process.env.NODE_ENV === "development";

// Bugsnag Initailization
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);


if(!isDevModeOn) {
  // Log Rocket Initalization
  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
  // after calling LogRocket.init()
  setupLogRocketReact(LogRocket);
}

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallBack}>
    <Router>
      <App />
    </Router>
  </ErrorBoundary>,
  document.getElementById("root")
);

// serviceWorker.register();
